import { AppProps } from 'next/app'
import GlobalStyles from '@/styles/globals'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider as Theme } from '@/hooks/useTheme'
import { RootProvider } from '@/hooks/root'
import { registerLocale } from 'react-datepicker'
import br from 'date-fns/locale/pt-BR'

registerLocale('br', br)

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <Theme>
      <RootProvider>
        <GlobalStyles />
        <Component {...pageProps} />
      </RootProvider>
    </Theme>
  )
}

export default MyApp
