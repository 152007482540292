import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback
} from 'react'

import { FaKeyboard, FaFileAlt, FaReceipt } from 'react-icons/fa'
import { RiCurrencyLine } from 'react-icons/ri'
import { IoIosSettings } from 'react-icons/io'
import { useAuth } from './useAuth'
import { PermissionsEnum, PermissionsList } from '@/@types/permission_list'
import { AiTwotoneTool } from 'react-icons/ai'
import { BsFillFileEarmarkSpreadsheetFill } from 'react-icons/bs'

export interface IOption {
  title: string
  route: string
  routeParent: string
  permission: PermissionsList
  options?: {
    title: string
    route: string
    permission?: PermissionsList
  }[]
}

export interface IRoute {
  title: string
  icon: object // eslint-disable-line
  route?: string
  isDropdown: boolean
  options?: IOption[]
}

interface IActive {
  title: string
  route: string
  parent?: {
    title: string
    route: string
  }
}

const items: IRoute[] = [
  {
    title: 'Cadastro',
    icon: <FaFileAlt />,
    route: '/registers',
    isDropdown: true
  },
  {
    title: 'Operacional',
    icon: <FaKeyboard />,
    route: '/operational',
    isDropdown: true
  },
  {
    title: 'Serviços',
    icon: <AiTwotoneTool />,
    route: '/services',
    isDropdown: true
  },
  {
    title: 'Financeiro',
    icon: <RiCurrencyLine />,
    route: '/finances',
    isDropdown: true
  },
  {
    title: 'Contábil',
    icon: <FaReceipt />,
    route: '/contabil',
    isDropdown: true
  },
  {
    title: 'Relatórios',
    icon: <BsFillFileEarmarkSpreadsheetFill />,
    route: '/reports',
    isDropdown: true
  },
  {
    title: 'Configurações',
    icon: <IoIosSettings />,
    route: '/settings',
    isDropdown: true
  }
]

const dropdownOptions: IOption[] = [
  {
    route: '/assignors',
    title: 'Cedentes',
    routeParent: '/registers',
    permission: 'Ver Cedentes (Root)'
  },
  {
    route: '/holdings',
    title: 'Holdings',
    routeParent: '/registers',
    permission: 'Ver Holdings (Root)'
  },
  {
    route: '/user-supervisors',
    title: 'Acompanhamento de Carteira',
    routeParent: '/registers',
    permission: 'Ver Supervisores (Root)'
  },
  {
    route: '/payers',
    title: 'Sacados',
    routeParent: '/registers',
    permission: 'Ver Sacados (Root)'
  },
  {
    route: '/associations',
    title: 'Sacados Associados',
    routeParent: '/registers',
    permission: 'Ver Holdings (Root)' // TODO
  },
  {
    route: '/suppliers',
    title: 'Fornecedores',
    routeParent: '/registers',
    permission: 'Ver Fornecedores (Root)'
  },
  {
    route: '/managers',
    title: 'Gerentes',
    routeParent: '/registers',
    permission: 'Ver Gerentes (Root)'
  },
  {
    route: '/agents',
    title: 'Agentes',
    routeParent: '/registers',
    permission: 'Ver Agentes (Root)'
  },
  {
    route: '/document-types',
    title: 'Tipos de Documentos',
    routeParent: '/registers',
    permission: 'Ver Tipos de Documentos (Root)'
  },
  {
    route: '/accounts',
    title: 'Contas Correntes',
    routeParent: '/registers',
    permission: 'Ver Contas Corrente (Root)'
  },
  {
    route: '/operations',
    title: 'Operações',
    routeParent: '/operational',
    permission: 'Ver Operações (Root)'
  },
  {
    route: '/title-confirmation',
    title: 'Confirmação e Cobrança',
    routeParent: '/operational',
    permission: 'Ver Performance de Carteira (Root)'
  },
  {
    route: '/operations-to-send',
    title: 'Operações a Enviar',
    routeParent: '/operational',
    permission: 'Ver Operações (Root)'
  },
  {
    route: '/prospected-payers',
    title: 'Prospecção de Sacados',
    routeParent: '/operational',
    permission: 'Ver Sacados (Root)'
  },
  {
    route: '/cnab-commands',
    title: 'Remessas CNAB',
    routeParent: '/finances',
    permission: 'Ver Remessas CNAB (Root)'
  },
  {
    route: '/cnab-returns',
    title: 'Retornos CNAB',
    routeParent: '/finances',
    permission: 'Ver Retornos CNAB (Root)'
  },
  {
    route: '/titles',
    title: 'Títulos',
    routeParent: '/finances',
    permission: 'Ver Movimentação de Títulos (Root)'
  },
  {
    route: '/fees',
    title: 'Tarifas',
    routeParent: '/finances',
    permission: 'Ver Movimentação de Tarifas (Root)'
  },
  {
    route: '/invoices',
    title: 'Contas a Pagar',
    routeParent: '/finances',
    permission: 'Ver Contas a Pagar (Root)'
  },
  {
    route: '/sends',
    title: 'Lançamentos',
    routeParent: '/contabil',
    permission: 'Ver Lançamentos Contábeis (Root)'
  },
  {
    route: '/pendencies',
    title: 'Pendências',
    routeParent: '/finances',
    permission: 'Ver Pendências Financeiras (Root)'
  },
  {
    route: '/debentures',
    title: 'Debêntures',
    routeParent: '/finances',
    permission: 'Ver Debêntures (Root)'
  },
  {
    route: '/checks-returned',
    title: 'Cheques Devolvidos',
    routeParent: '/finances',
    permission: 'Ver Devolução de Cheques (Root)'
  },
  {
    route: 'title-commission-control',
    title: 'Controle de Comissões',
    routeParent: '/finances',
    permission: 'Ver Controler de Comissões'
  },
  {
    route: '/fidc-conciliations',
    title: 'Conciliação FIDC',
    routeParent: '/finances',
    permission: 'Ver Conciliações FIDC (Root)'
  },
  {
    route: '/companies',
    title: 'Empresas',
    routeParent: '/settings',
    permission: 'Ver Cadastro de Empresas (Root)'
  },
  {
    route: '/users',
    title: 'Usuários',
    routeParent: '/settings',
    permission: 'Ver Cadastro de Usuários (Root)'
  },
  {
    route: '/coordinators',
    title: 'Coordenadores',
    routeParent: '/settings',
    permission: 'Ver Coordenadores (Root)'
  },
  // {
  //   route: '/billing-settings',
  //   title: 'Confirmação e Cobrança',
  //   routeParent: '/settings',
  //   permission: 'Ver Configuração de Esteiras (Root)'
  // },
  {
    route: '/params',
    title: 'Parametrização',
    routeParent: '/settings',
    permission: 'Ver Parametrização do Sistema (Root)'
  },
  {
    route: '/indexers',
    title: 'Indexadores',
    routeParent: '/settings',
    permission: 'Ver Debêntures (Root)' // TODO
  },
  {
    route: '/debenture-investors',
    title: 'Investidores',
    routeParent: '/settings',
    permission: 'Ver Debêntures (Root)'
  },
  {
    route: '/holidays',
    title: 'Feriados',
    routeParent: '/settings',
    permission: 'Ver Parametrização do Sistema (Root)' // TODO
  },
  {
    route: '/contract',
    title: 'Contratos',
    routeParent: '/settings',
    permission: 'Ver Cadastro de Empresas (Root)'
  },
  {
    route: '/email-templates',
    title: 'Templates de E-mail',
    routeParent: '/settings',
    permission: 'Ver Cadastro de Empresas (Root)' // TODO
  },
  {
    route: '/protest',
    title: 'Consulta Protesto',
    routeParent: '/services',
    permission: 'Consultar Protesto (Root)'
  },
  {
    route: '/serasa',
    title: 'Consulta Serasa',
    routeParent: '/services',
    permission: 'Ver Serviço de Consulta Serasa (Root)'
  },
  {
    route: '/vadu',
    title: 'Consulta Vadu',
    routeParent: '/services',
    permission: 'Ver Serviço de Consulta Serasa (Root)'
  },
  // {
  //   route: '/company-consult',
  //   title: 'Consulta Empresas',
  //   routeParent: '/services',
  //   permission: 'Consultar Empresas (Root)'
  // },
  {
    route: '/e-confirmation/nfe',
    title: 'Confirmação Eletrônica NFe',
    routeParent: '/services',
    permission: 'Acesso à Confirmação Eletrônica (Root)'
  },
  {
    route: '/e-confirmation/cte',
    title: 'Confirmação Eletrônica CTe',
    routeParent: '/services',
    permission: 'Acesso à Confirmação Eletrônica (Root)'
  },
  {
    route: '/charge',
    title: 'Notificação/Cobrança',
    routeParent: '/services',
    permission: 'Ver Serviço de Notificação/Cobrança (Root)'
  },
  {
    route: '/facta-juridical-notifications',
    title: 'Notificações Extrajudiciais',
    routeParent: '/services',
    permission: 'Ver Serviço de Notificação Extrajudicial (Root)'
  },
  {
    route: '/send-mail',
    title: 'Envios de E-mail',
    routeParent: '/services',
    permission: 'Ver Serviço de Envio de E-mail (Root)'
  },
  {
    route: '/national-unicity',
    title: 'Unicidade Nacional de Títulos',
    routeParent: '/services',
    permission: 'Ver Unicidade Nacional de Títulos'
  },
  {
    route: '/serasa-reciprocidade',
    title: 'Serasa Reciprocidade',
    routeParent: '/services',
    permission: 'Ver Serviço de Serasa Reciprocidade (Root)'
  },
  {
    route: '/title-registration',
    title: 'Títulos Registrados (QCertifica)',
    routeParent: '/services',
    permission: 'Ver Registro de Títulos (QCertifica)'
  },
  {
    route: '/account-plans',
    title: 'Plano de Contas',
    routeParent: '/contabil',
    permission: 'Ver Plano de Contas (Root)'
  },
  {
    route: '/contabil-events',
    title: 'Eventos Contábeis',
    routeParent: '/contabil',
    permission: 'Ver Eventos Contábeis (Root)'
  },
  {
    route: '',
    title: 'Títulos',
    routeParent: '/reports',
    permission: 'Opções no menu Relatórios Títulos (Root)',
    options: [
      {
        title: 'Títulos em Aberto',
        route: '/reports/titles/open',
        permission: 'Ver Relatório de Títulos em Aberto (Root)'
      },
      {
        title: 'Títulos Liquidados',
        route: '/reports/titles/paid',
        permission: 'Ver Relatório de Títulos Liquidados (Root)'
      },
      {
        title: 'Títulos Recomprados',
        route: '/reports/titles/rebuyed',
        permission: 'Ver Relatório de Títulos Recomprados (Root)'
      },
      {
        title: 'Títulos Não confirmados',
        route: '/reports/titles/not-confirmed',
        permission: 'Ver Relatório de Títulos não Confirmados (Root)'
      },
      {
        title: 'Títulos em Cobrança',
        route: '/reports/titles/in-billing',
        permission: 'Ver Relatório de Títulos em Cobrança (Root)'
      },
      {
        title: 'Títulos pagos em duplicidade',
        route: '/reports/titles/paid-in-duplicity',
        permission: 'Ver Relatório de Títulos Pagos em Duplicidade (Root)'
      },
      {
        title: 'Local de pagamento',
        route: '/reports/titles/payment-place',
        permission: 'Ver Relatório de Praça de pagamento (Root)'
      },
      {
        route: '/reports/titles/extension',
        title: 'Prorrogação',
        permission: 'Ver Relatório de Títulos Prorrogados (Root)'
      },
      {
        route: '/reports/titles/receipt_by_write_off_type',
        title: 'Recibo por tipo de baixa',
        permission: 'Ver Relatório de Recibo por Tipo de Baixa (Root)'
      },
      {
        route: '/reports/titles/titles-occurrences',
        title: 'Ocorrências de Títulos',
        permission: 'Ver Relatório de Ocorrências de Títulos (Root)'
      }
    ]
  },
  {
    route: '',
    title: 'Operacionais',
    routeParent: '/reports',
    permission: 'Opções no menu Relatórios Operacionais (Root)',
    options: [
      {
        route: '/reports/operational/operations',
        title: 'Operações',
        permission: 'Ver Relatório de Operações (Root)'
      },
      {
        route: '/reports/operational/confirmations',
        title: 'Confirmações',
        permission: 'Ver Relatório de Confirmações (Root)'
      },
      {
        route: '/reports/operational/acompanhamento-cobranca',
        title: 'Acompanhamento de Cobrança',
        permission: 'Ver Relatório de Acompanhamento de Cobrança (Root)'
      },
      {
        route: '/reports/operational/extract-assignor',
        title: 'Extrato do Cliente',
        permission: 'Ver Relatório de Extrato do Cliente (Root)'
      },
      {
        route: '/reports/operational/assignor-acquisition',
        title: 'Aquisições por Cedente',
        permission: 'Ver Relatório de Aquisições por Cedente (Root)'
      }
    ]
  },
  {
    route: '',
    title: 'Financeiros',
    routeParent: '/reports',
    permission: 'Opções no menu Relatórios Financeiros (Root)',
    options: [
      {
        route: '/reports/financial/wallet',
        title: 'Acompanhamento de Carteira',
        permission: 'Ver Relatório de Acompanhamento de Carteira (Root)'
      },
      {
        route: '/reports/financial/assignor-portrayal',
        title: 'Retratos dos Cedentes',
        permission: 'Ver Relatório de Retrato de Cedentes (Root)'
      },
      {
        route: '/reports/financial/checks-returned',
        title: 'Cheques Devolvidos',
        permission: 'Ver Relatório de Cheques Devolvidos (Root)'
      },
      {
        route: '/reports/financial/financial-pendings',
        title: 'Pendências Financeiras',
        permission: 'Ver Relatório de Pendências Financeiras (Root)'
      },
      {
        route: '/reports/financial/entries-by-accounting-plans',
        title: 'Lançamentos por Plano de Contas',
        permission: 'Ver Relatório de Lançamentos por Planos de Contas (Root)'
      },
      {
        route: '/reports/financial/cashier-flow',
        title: 'Fluxo de Caixa',
        permission: 'Ver Relatório de Fluxo de Caixa'
      }
    ]
  },
  {
    route: '',
    title: 'Gerenciais',
    routeParent: '/reports',
    permission: 'Opções no menu Relatórios Gerenciais (Root)',
    options: [
      {
        route: '/reports/management/revenues',
        title: 'Receitas',
        permission: 'Ver Relatório de Receitas (Root)'
      },
      {
        route: '/reports/management/provision-calc',
        title: 'Cálculo de Provisão',
        permission: 'Ver Relatório de Cálculo de Provisão (Root)'
      },
      {
        route: '/reports/management/revenue',
        title: 'Apropriação de Receita',
        permission: 'Ver Relatório de Apropriação de Receita (Root)'
      },
      {
        route: '/reports/management/commission',
        title: 'Comissão de Gerentes',
        permission: 'Ver Relatório de Comissão de Gerentes'
      },
      {
        route: '/reports/management/wallet-portrait',
        title: 'Retrato da Carteira',
        permission: 'Ver Relatório de Retrato da Carteira (Root)'
      },
      {
        route: '/reports/management/wallet-position',
        title: 'Posição de Carteira',
        permission: 'Ver Relatório de Posição de Carteira (Root)'
      },
      {
        route: '/reports/management/face-value',
        title: 'Valor de Face',
        permission: 'Ver Relatório de Valor de Face (Root)'
      }
    ]
  },
  {
    route: '',
    title: 'Cadastros',
    routeParent: '/reports',
    permission: 'Opções no menu Relatórios Cadastrais',
    options: [
      {
        route: '/reports/register/procurators',
        title: 'Procuradores',
        permission: 'Ver Relatório de Procuradores'
      }
    ]
  }
]

interface MenuContext {
  config: IRoute[]
  active: IActive | null
  onChangeActive: (route: string, isSource?: boolean) => void
  resetActive: () => void
  open: boolean
  setOpen: (v: boolean) => void
}

export const Menu = createContext<MenuContext>({} as MenuContext)

export const MenuProvider: React.FC = ({ children }) => {
  const [active, setActive] = useState<IActive | null>(null)
  const [open, setOpen] = useState(false)
  const [config, setConfig] = useState<any[]>([])

  const { permissions } = useAuth()

  const hasPermission = useCallback(
    (permission: PermissionsList) => {
      if (permissions?.find(p => p.slug === PermissionsEnum[permission])) {
        return true
      }
      return false
    },
    [permissions]
  )

  useEffect(() => {
    const [route] = window.location.pathname.split('/').filter(i => i)

    if (window.location.pathname === '/') {
      return setActive(null)
    }

    const option = dropdownOptions.find(o => o.route === `/${route}`)
    const optionDropdown = dropdownOptions.find(
      o =>
        o.options && o.options?.find(d => d.route === window.location.pathname)
    )

    if (option) {
      const item = items.find(i => i.route === option.routeParent)
      if (item) {
        setActive({
          route: `/${route}`,
          title: option.title,
          parent: {
            route: item.route,
            title: item.title
          }
        })
      }
    } else if (optionDropdown) {
      const dropItem = optionDropdown.options?.find(
        o => o.route === window.location.pathname
      )
      if (dropItem) {
        setActive({
          route: `/${window.location.pathname}`,
          title: dropItem.title
        })
      }
    }
  }, [])

  useEffect(() => {
    if (permissions?.length > 0) {
      setConfig(
        items
          .map(i => {
            if (i.isDropdown) {
              return {
                ...i,
                options: dropdownOptions
                  .filter(
                    p =>
                      p.routeParent === i.route && hasPermission(p.permission)
                  )
                  .map(option => {
                    if (option.options) {
                      return {
                        ...option,
                        options: option.options.filter(subOption => {
                          if (subOption.permission) {
                            return hasPermission(subOption.permission)
                          }
                          return subOption
                        })
                      }
                    }
                    return option
                  })
              }
            }
            return i
          })
          .filter(item => item.options.length > 0)
      )
    }
  }, [permissions, hasPermission])

  function onChangeActive(route: string, isSource?: boolean) {
    if (!isSource) {
      const item = dropdownOptions.find(o => o.route === route)
      console.log(route)
      const optionDropdown = dropdownOptions.find(
        o => o.options && o.options?.find(d => d.route === route)
      )
      if (item) {
        const parent = items.find(p => p.route === item.routeParent)
        const d = {
          route,
          title: item.title,
          parent: {
            title: parent.title,
            route: parent.route
          }
        }
        setActive(d)
        localStorage.setItem('menuActive', JSON.stringify(d))
      } else if (optionDropdown) {
        const option = optionDropdown.options?.find(o => o.route === route)
        if (option) {
          const d = {
            route,
            title: option.title
          }
          setActive(d)
          localStorage.setItem('menuActive', JSON.stringify(d))
        }
      }
    } else {
      const item = items.find(o => o.route === route)
      if (item) {
        const d = {
          route,
          title: item.title
        }
        setActive(d)
        localStorage.setItem('menuActive', JSON.stringify(d))
      }
    }
  }

  function resetActive() {
    setActive(null)
  }

  return (
    <Menu.Provider
      value={{ open, setOpen, config, active, onChangeActive, resetActive }}
    >
      {children}
    </Menu.Provider>
  )
}

export const useMenu = (): MenuContext => {
  const context = useContext(Menu)

  if (!context) {
    throw new Error('The hook useMenu must be used within an MenuProvider')
  }

  return context
}
